import { defineStore } from 'pinia';
import api from '@/services/api';
import { useCalculatorStore } from '@/stores/useCalculatorStore';

export const useDossierStore = defineStore('dossier', {
  state: () => ({
   
  }),
  actions: {
    async deleteDossierData(id) {
      try {
        const response = await api.delete(`/dossier/deleteDossierData/${id}`);
        if (response.data) {
          const calculatorStore = useCalculatorStore();
          calculatorStore.dossierData = response.data.dossier;
        }
        return response.data;
      } catch (error) {
        console.error('Error al eliminar el dossier:', error);
      }
    }
  },
});
