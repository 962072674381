<template>
    <div class="flex z-10 gap-6">
        <img src="/img/presentacion/1.TH.logo.png" alt="Slide 10" class="h-[48px]">
        <p class="text-[36px] font-medium">Guest Management System (GMS)</p>
    </div>
    <div class="flex justify-center items-center h-[610px] mt-8">
        <div class="flex flex-col justify-center items-center h-full">
            <p class="text-[30px] font-medium absolute top-[80px] left-[490px] text-[#212121]">ADQUISICIÓN</p>
            <img src="/img/presentacion/flechas/flechas1.png" 
                alt="Slide 10" 
                class="w-[90%] max-h object-contain">
        </div>
    </div>
</template>