<template>
    <!-- Imagen del mockup: z-10 pero sin bloquear interacción -->
    <img 
      src="/img/presentacion/mockup/mockup.svg" 
      alt="Slide 21" 
      class="w-[412px] h-[732px] absolute left-1/2 -translate-x-1/2 z-10 pointer-events-none"
    />
  
    <!-- Frame del móvil -->
    <div class="mobile-frame w-[327px] h-[700px] absolute left-0 z-1">
      <iframe 
        id="webappIframe"
        src="https://tuhotelcadena.thehoster.app/hotelcadena/?e=406&g=256"
        class="w-full h-full absolute top-0 left-0 border-none"
        frameborder="0">
          Tu navegador no soporta iframes, por favor actualiza a una versión más reciente.
      </iframe>
    </div>
  </template>
  
  <style scoped>
  .mobile-frame {
    border: none;
    overflow: hidden;
    border-radius: 36px;
    box-shadow: 0 4px 14px rgba(0,0,0,0.3);
    position: relative;
    background-color: black;
  }
  
  .mobile-frame:before {
    content: '';
    display: block;
    position: absolute;
    transform: translateX(-50%);
    width: 210px;
    height: 30px;
    background: #000;
    border-radius: 0 0 20px 20px;
  }
  </style>
  