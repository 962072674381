<template>
    <div class="mobile-frame w-[1640px] h-[700px] absolute left-[0px] z-1 between-1440-1919:w-[1380px] between-1440-1919:h-[810px] ">
        <iframe 
            id="webappIframe"
            :src="iframeSrc"
            class="w-full h-full absolute top-0 left-0 border-none"
            frameborder="0">
                Tu navegador no soporta iframes, por favor actualiza a una versión más reciente.
        </iframe>

    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const iframeSrc = ref('');
const iframeId = 'webappIframe';
const hosterUrl = process.env.VUE_APP_HOSTER_URL;

function clearIframeStorage() {
    const iframe = document.getElementById(iframeId);
    iframe?.contentWindow?.postMessage("clearStorage", hosterUrl);

    // Escucha confirmación y recarga el iframe
    window.addEventListener("message", (event) => {
        if (event.origin === hosterUrl && event.data === "storageCleared") {
            iframe.contentWindow.location.reload();
        }
    });
}

onMounted(() => {
    const user = process.env.VUE_APP_HOSTER_USER;
    const password = process.env.VUE_APP_HOSTER_PASSWORD;
    iframeSrc.value = `${hosterUrl}/login?u=${user}&p=${password}`;
    //console.log(iframeSrc.value);

    // Espera 2 segundos y luego limpia el storage del iframe
    setTimeout(() => {
        clearIframeStorage();
    }, 1500);
});
</script>



<style scoped>
    .mobile-frame {
        border: none;
        overflow: hidden;
        border-radius: 36px;
        box-shadow: 0 4px 14px rgba(0,0,0,0.3);
        position: relative;
        background-color: black;
    }

    .mobile-frame:before {
        content: '';
        display: block;
        position: absolute;
      
        transform: translateX(-50%);
        width: 210px;
        height: 30px;
        background: #000;
        border-radius: 0 0 20px 20px;
    }

</style>
