<template>
    <div class="flex  w-full h-screen">
      <!-- Columna izquierda con imágenes -->
      <div class="relative w-[650px] flex justify-start items-center">
        <!-- Imagen trasera (absoluta, detrás) -->
        <img 
          src="/img/presentacion/hotel/2.svg"
          alt="Slide 23" 
          class="absolute left-[250px] top-[46%] -translate-y-1/2 z-10 w-[316px] h-[760px]"
        />
        <!-- Imagen delantera -->
        <img 
          src="/img/presentacion/hotel/1.svg"
          alt="Slide 23" 
          class="relative z-20  top-[45%] -translate-y-1/2 w-[316px] h-[760px]"
        />
      </div>
  
      <!-- Columna derecha con contenido -->
      <div class="flex flex-col justify-start mt-[76px] flex-1  space-y-12">
        <!-- Título principal -->
        <div class="flex gap-4 items-center">
          <div class="p-[5px] bg-[#34A98F] rounded-[7.5px]">
            <img src="/img/presentacion/hotel/WA.alojamiento.svg" alt="Slide 23" class="w-[70px] h-[70px]" />
          </div>
          <h3 class="text-[60px] font-semibold text-[#0B6357]">Hotel</h3>
        </div>
  
        <!-- Texto 1 -->
        <div class="flex items-start gap-4">
          <img src="/img/presentacion/TH.CHECK.svg" alt="Check" class="w-6 h-6 mt-2" />
          <div class="text-[24px] flex flex-col">
            <h3 class="font-medium text-[#0B6357]">Información al instante</h3>
            <h3 class="font-normal">Toda la información que tu huésped necesita en la palma de su mano.</h3>
          </div>
        </div>
  
        <!-- Texto 2 -->
        <div class="flex items-start gap-4">
          <img src="/img/presentacion/TH.CHECK.svg" alt="Check" class="w-6 h-6 mt-2" />
          <div class="text-[24px] flex flex-col">
            <h3 class="font-medium text-[#0B6357]">Comunicación constante</h3>
            <h3 class="font-normal">Conecta con tus huéspedes antes, durante y después de su estancia.</h3>
          </div>
        </div>
  
        <!-- Texto 3 -->
        <div class="flex items-start gap-4">
          <img src="/img/presentacion/TH.CHECK.svg" alt="Check" class="w-6 h-6 mt-2" />
          <div class="text-[24px] flex flex-col">
            <h3 class="font-medium text-[#0B6357]">Reducción de carga operativa</h3>
            <h3 class="font-normal">Menos preguntas en recepción, más tiempo para lo importante.</h3>
          </div>
        </div>
      </div>
    </div>
  </template>
  