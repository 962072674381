<template>
    <div class="flex items-center gap-4">
        <ButtonTab 
            v-for="(dossier, index) in dataDossiers" 
            :key="dossier.id"
            :active="dossier.tab_number === store.activeTab"
            @click="selectData(dossier.tab_number)"
            class="relative flex items-center justify-between"
            :class="{'pr-10': index !== 0}"
        >
            Escenario {{ index + 1 }}

            <img 
                v-if="index > 0"
                src="/img/presentacion/1.TH.CLOSE.svg" 
                alt="Cerrar" 
                class="w-[19px] h-[19px] cursor-pointer absolute right-3 transition hover:opacity-80"
                @click.stop="handleCloseScenario(dossier.id, dossier.tab_number, index)" 
            />
        </ButtonTab>
    </div>

    <div 
        @click="addNewScenario" 
        class="flex p-3 h-[42px] justify-center items-center gap-2 cursor-pointer rounded-[10px] border border-[#E9E9E9] bg-white text-gray-700 hover:bg-gray-100 hover:border-black group"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="w-5 h-5 stroke-gray-500 group-hover:stroke-black">
            <path d="M10.0001 1.66675V10.0001M10.0001 10.0001V18.3334M10.0001 10.0001H1.66675M10.0001 10.0001H18.3334" stroke-width="2.5" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script setup>
import ButtonTab from '@/components/ButtonTab.vue';
import { useCalculatorStore } from '@/stores/useCalculatorStore';
import { useDossierStore } from '@/stores/useDossierStore';
import { ref, watch } from 'vue';

const store = useCalculatorStore();
const dossierStore = useDossierStore();
const emit = defineEmits(['selectData']);

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    }
});

// Estado reactivo para los escenarios
const dataDossiers = ref([...props.data.dossier_data]);

// Sincronizar cambios en la prop
watch(() => props.data.dossier_data, (newData) => {
    dataDossiers.value = [...newData];
});

const selectData = (tab_number) => {
    emit('selectData', tab_number);
};

const addNewScenario = async () => {
    await store.addNewScenario(store.form);
};

const handleCloseScenario = async (id, tabNumber) => {
    const response = await dossierStore.deleteDossierData(id);

    if (!response.error) {
        // Guardamos la referencia del tab eliminado
        const deletedTabIndex = dataDossiers.value.findIndex(d => d.tab_number === tabNumber);

        // Eliminamos el escenario del estado local
        dataDossiers.value = dataDossiers.value.filter(d => d.id !== id);

        // Si el tab eliminado era el activo, seleccionamos otro automáticamente
        if (store.activeTab === tabNumber) {
            let newTab = null;

            if (deletedTabIndex > 0) {
                // Si hay un tab anterior, lo seleccionamos
                newTab = dataDossiers.value[deletedTabIndex - 1]?.tab_number;
            } else {
                // Si no hay anterior, seleccionamos el siguiente
                newTab = dataDossiers.value[deletedTabIndex]?.tab_number;
            }

            // Si ya no hay escenarios, seleccionamos el primero
            if (!newTab && dataDossiers.value.length > 0) {
                newTab = dataDossiers.value[0].tab_number;
            }

            if (newTab) {
                selectData(newTab);
            }
        }

        //alert('Escenario cerrado correctamente');
    }
};
</script>
