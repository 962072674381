import {  watch, ref } from 'vue';

export function useDiscountCalculator(store, basePriceA, basePriceB, basePriceImplementation) {

// descuento coste de servicio / precio por hab al mes
  const valueDiscountA = ref(0);
  const valueDiscountB = ref(0);
  const ShowDiscountA = ref(false);
  const ShowDiscountB = ref(false);

  // descuento precio de implementación
  const valueDiscountImplementation = ref(0);
  const ShowDiscountImplementation = ref(false);

  // Watcher para A descuento coste de servicio / precio por hab al mes
  watch(
    () => store.form.pricePerRoomPerMonth,
    (newValue) => {
      const currentValue = parseFloat(newValue);
      if (isNaN(basePriceA) || isNaN(currentValue)) {
        ShowDiscountA.value = false;
        valueDiscountA.value = 0;
        return;
      }

      if (currentValue < basePriceA) {
        const discount = ((basePriceA - currentValue) / basePriceA) * 100;
        valueDiscountA.value = Math.ceil(discount);
        ShowDiscountA.value = true;
      } else {
        valueDiscountA.value = 0;
        ShowDiscountA.value = false;
      }
    },
    { immediate: true }
  );

  // Watcher para B
  watch(
    () => store.form.pricePerRoomPerMonth,
    (newValue) => {
      const currentValue = parseFloat(newValue);
      if (isNaN(basePriceB) || isNaN(currentValue)) {
        ShowDiscountB.value = false;
        valueDiscountB.value = 0;
        return;
      }

      if (currentValue < basePriceB) {
        const discount = ((basePriceB - currentValue) / basePriceB) * 100;
        valueDiscountB.value = Math.ceil(discount);
        ShowDiscountB.value = true;
      } else {
        valueDiscountB.value = 0;
        ShowDiscountB.value = false;
      }
    },
    { immediate: true }
  );
  //fin descuento coste de servicio / precio por hab al mes

  // Watcher para descuento precio de implementación
  watch(
    () => store.form.implementationPrice,
    (newValue) => {
      const currentValue = parseFloat(newValue);
      if (isNaN(basePriceImplementation) || isNaN(currentValue)) {
        ShowDiscountImplementation.value = false;
        valueDiscountImplementation.value = 0;
        return;
      }

      if (currentValue < basePriceImplementation) {
        const discount = ((basePriceImplementation - currentValue) / basePriceImplementation) * 100;
        valueDiscountImplementation.value = Math.ceil(discount);
        ShowDiscountImplementation.value = true;
      } else {
        valueDiscountImplementation.value = 0;
        ShowDiscountImplementation.value = false;
      }
    },
    { immediate: true }
  );

  return {
    valueDiscountA,
    valueDiscountB,
    ShowDiscountA,
    ShowDiscountB,
    valueDiscountImplementation,
    ShowDiscountImplementation
  };
}
