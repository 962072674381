<template>
  <SliderComponent useScroll :showButtons="true">
    <!-- Slide 1 -->
    <template #slide-1>
        <ProfitCalculator :data="data" shortMode:shortMode @updateDossierData="addNewDossier"/>
    </template>

    <!-- Slide 10 -->
    <template #slide-2>
      <Part1Component />
    </template>
    <template #slide-3>
      <Part2Component />
    </template>
    <template #slide-4>
      <Part3Component />
    </template>
    <template #slide-5>
      <Part4Component />
    </template>
    <template #slide-6>
        <Part5Component />
    </template>
    <template #slide-7>
        <Part6Component />
    </template>
    <template #slide-8>
      <Part7Component />
    </template>
    <template #slide-9>
      <p class="text-[60px] font-semibold">2 Plataformas</p>
      <div class="flex flex-col top-[185px] left-[110px] absolute w-[16%] justify-start items-start">
        <!-- bacggroun image bg2plataformas.svg-->
        <img src="/img/presentacion/bg2plataformas2.svg" alt="Slide 7" class="w-[100%] absolute h-[100%] object-contain">
        <span class="text-[35px] font-semibold text-[#333333] z-10">WEBAPP</span>
        <span class="text-[20px] font-normal text-[#212121] z-10">App en línea que no requiere descarga y completamente personalizada. Potencia la experiencia del huésped en toda su estancia.</span>
      </div>

      <div class="flex flex-col bottom-[185px] right-[110px] absolute w-[16%] justify-start items-start">
        <!-- bacggroun image bg2plataformas.svg-->
        <img src="/img/presentacion/bg2plataformas.svg" alt="Slide 7" class="w-[100%] absolute h-[100%] object-contain">
        <span class="text-[35px] font-semibold text-[#333333] z-10">BACKOFFICE</span>
        <span class="text-[20px] font-normal text-[#212121] z-10">Opera las estancias digitales de tus huéspedes y adapta la WebApp a tus preferencias.</span>
      </div>
        <!-- imagen en el medio de la pantalla -->
        <div class="flex justify-center items-center h-[360px]">
          <img src="/img/presentacion/2plataformas.svg" alt="Slide 7" class=" mt-[400px] w-[50%] max-h object-contain">
        </div>
    </template>
   <!--  <template #slide-10>
      <BackgroundGradient>
        <img src="/img/presentacion/mcaratula.png" alt="Slide 7" class="w-screen h-screen object-cover transform scale-[1.55] translate-x-[-1%]">
      </BackgroundGradient>
    </template> -->
    <template #slide-10>
      <!-- <BackgroundGradient> -->
         <HotelComponent/>
      <!-- </BackgroundGradient> -->

    </template>
    <template #slide-11>
        <CheckinComponent/>
    </template>
    <template #slide-12>
        <DestinationComponent/>
    </template>
    <template #slide-13>
        <ServicesComponent/>
    </template>
    <template #slide-14>
        <ReputationComponent/>
    </template>
    <template #slide-15>
        <FidelizationComponent/>
    </template>
    <!-- <template #slide-16>
        <img src="/img/presentacion/timeline/1.svg" alt="Slide 7" class="w-full  top-0 absolute ">
    </template> -->
    <template #slide-17>
      <Promote1Component/>
    </template>
    <template #slide-18>
      <Promote2Component/>
    </template>
    <template #slide-19>
      <Promote3Component/>
    </template>
    <template #slide-20>
      <!-- line email de bienvenida -->
      <div class="absolute h-[35px] z-[500]">
        <div class="absolute left-[263px] top-[280px] h-full w-2 bg-[#34A98F]"></div>
      </div>
      <div class="p-3 bg-[#34A98F] w-[292px] h-[107px] rounded-[20px] absolute left-[145px] top-[310px] flex items-start justify-center">
        <h3 class="text-[24px] font-semibold text-white">E-mail de bienvenida</h3>
      </div>
      <img src="/img/presentacion/emails/bienvenida.svg" alt="Slide 10" class="w-[275px] h-[725px] left-[151px] top-[360px] absolute">

       <!-- line email de prepara tu llegada -->
       <div class="absolute h-[35px] z-[500]">
        <div class="absolute left-[540px] top-[280px] h-full w-2 bg-[#34A98F]"></div>
      </div>
      <div class="p-3 bg-[#34A98F] w-[292px] h-[107px] rounded-[20px] absolute left-[448px] top-[310px] flex items-start justify-center">
        <h3 class="text-[24px] font-semibold text-white">¡Prepara tu llegada!</h3>
      </div>
      <img src="/img/presentacion/emails/prepara.svg" alt="Slide 10" class="w-[275px] h-[725px] left-[455px] top-[360px] absolute">

       <!-- line email ¿Qué tal va todo?-->
       <div class="absolute h-[35px] z-[500]">
        <div class="absolute left-[876px] top-[280px] h-full w-2 bg-[#34A98F]"></div>
      </div>
      <div class="p-3 bg-[#34A98F] w-[292px] h-[107px] rounded-[20px] absolute left-[780px] top-[310px] flex items-start justify-center">
        <h3 class="text-[24px] font-semibold text-white">¿Qué tal va todo?</h3>
      </div>
      <img src="/img/presentacion/emails/quetal.svg" alt="Slide 10" class="w-[275px] h-[725px] left-[787px] top-[360px] absolute">

      <!-- line email de despedida -->
      <div class="absolute h-[35px] z-[500]">
        <div class="absolute left-[1350px] top-[280px] h-full w-2 bg-[#34A98F]"></div>
      </div>
      <div class="p-3 bg-[#34A98F] w-[292px] h-[107px] rounded-[20px] absolute left-[1215px] top-[310px] flex items-start justify-center">
        <h3 class="text-[24px] font-semibold text-white">E-mail de despedida</h3>
      </div>
      <img src="/img/presentacion/emails/despedida.svg" alt="Slide 10" class="w-[275px] h-[725px] left-[1220px] top-[360px] absolute">

       <!-- line email de regresa -->
      <div class="absolute h-[35px] z-[500]">
        <div class="absolute left-[1662px] top-[280px] h-full w-2 bg-[#34A98F]"></div>
      </div>
      <div class="p-3 bg-[#34A98F] w-[292px] h-[107px] rounded-[20px] absolute left-[1550px] top-[310px] flex items-start justify-center">
        <h3 class="text-[24px] font-semibold text-white">¡Regresa!</h3>
      </div>
      <img src="/img/presentacion/emails/regresa.svg" alt="Slide 10" class="w-[275px] h-[725px] left-[1560px] top-[360px] absolute">

      <img src="/img/presentacion/timeline/3.svg" alt="Slide 7" class=" absolute z-10 ">

      
    </template>
    <template #slide-21>
      <div class="flex flex-col items-center justify-center mt-[50px]">
          <MockupHoster/>
      </div>
    </template>
    <template #slide-22>
        <ResumeScroll/>
    </template>
    <template #slide-23>
       <PriceSlider/>
    </template>
    <template #slide-24>
       <PriceSlider complete/>
    </template>
    <template #slide-25>
      <PriceSlider complete price/>
    </template>
    <template #slide-26>
      <div class="flex flex-col justify-center items-center h-[835px] w-full">
        <Calculator shortMode:shortMode :data="data" @updateDossierData="addNewDossier"/>
      </div>
    </template>
    <template #slide-27>
      <div class="grid grid-cols-3">
        <div class="flex flex-col items-center justify-center">
          <p class="text-[35px] font-medium uppercase items-center ml-[90px]">contrato</p>
          <img src="/img/presentacion/pasos/contrato.png" alt="Slide 20" class="w-[500px]">

          <div class="flex flex-col items-center justify-center mt-5">
            <div class="flex flex-col items-start justify-start gap-6">
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">3 cláusulas</p>
              </div>
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">Firma en 1 minuto</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center">
          <p class="text-[35px] font-medium uppercase items-center ml-[90px]">implementación</p>
          <img src="/img/presentacion/pasos/implementacion.png" alt="Slide 20" class="w-[500px]">
          <div class="flex flex-col items-center justify-center mt-5">
            <div class="flex flex-col items-start justify-center gap-6 ml-[100px]">
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">Nosotros nos encargamos</p>
              </div>
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">En menos de 72 horas</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center">
          <p class="text-[35px] font-medium uppercase items-center ml-[90px]">capacitación</p>
          <img src="/img/presentacion/pasos/capacitacion.png" alt="Slide 20" class="w-[500px]">

          <div class="flex flex-col items-center justify-center mt-5">
            <div class="flex flex-col items-start justify-center gap-6 ml-[100px]">
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">Capacitación a director</p>
              </div>
              <div class="flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M37.7085 9.06999C37.6735 9.10398 37.6406 9.14016 37.6101 9.1783L20.5128 30.9621L10.2092 20.6536C9.5093 20.0014 8.58357 19.6463 7.62704 19.6632C6.67052 19.6801 5.75788 20.0676 5.08141 20.7441C4.40494 21.4205 4.01745 22.3332 4.00058 23.2897C3.9837 24.2462 4.33875 25.1719 4.99094 25.8719L18.0169 38.9028C18.3678 39.253 18.7857 39.5291 19.2456 39.7143C19.7055 39.8996 20.198 39.9904 20.6937 39.9812C21.1895 39.972 21.6783 39.863 22.131 39.6609C22.5837 39.4587 22.991 39.1674 23.3287 38.8043L42.981 14.239C43.6501 13.5367 44.016 12.5992 43.9995 11.6293C43.9829 10.6593 43.5853 9.73483 42.8926 9.05569C42.1999 8.37656 41.2677 7.99734 40.2976 8.00001C39.3275 8.00269 38.3975 8.38705 37.7085 9.06999Z" fill="#34A98F"/>
              </svg>
              <p class="text-[26px] font-semibold ">En menos de 1 hora</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #slide-28>
        <!-- mockup webapp middle -->
        <div class="flex flex-col items-center justify-center mt-[82px] between-1440-1919:mt-[42px]">
           <MockupWebapp/>
        </div>
    </template>
    <template #slide-29>
      <div class="flex flex-col items-center justify-center mt-[50px]">
        <MockupHoster/>
      </div>
    </template>
    
  </SliderComponent>
</template>

<script setup>
  import { onMounted, computed } from 'vue';
  import SliderComponent from './SliderComponent.vue';
  import Calculator from '../../components/CalculatorSlider.vue';
  import ProfitCalculator from '../../components/ProfitCalculator.vue';

  //import BackgroundGradient from './Components/BackgroundGradient.vue';
  import PriceSlider from './Components/PriceSlider.vue';
  import MockupWebapp from './Components/MockupWebapp.vue';
  import MockupHoster from './Components/MockupHoster.vue';

  //how do it
  import HotelComponent from './Components/howDoIt/HotelComponent.vue';
  import CheckinComponent from './Components/howDoIt/CheckinComponent.vue';
  import DestinationComponent from './Components/howDoIt/DestinationComponent.vue';
  import ServicesComponent from './Components/howDoIt/ServicesComponent.vue';
  import ReputationComponent from './Components/howDoIt/ReputationComponent.vue';
  import FidelizationComponent from './Components/howDoIt/FidelizationComponent.vue';

  //what do it
  import Part1Component from './Components/whatDo/Part1Component.vue';
  import Part2Component from './Components/whatDo/Part2Component.vue';
  import Part3Component from './Components/whatDo/Part3Component.vue';
  import Part4Component from './Components/whatDo/Part4Component.vue';
  import Part5Component from './Components/whatDo/Part5Component.vue';
  import Part6Component from './Components/whatDo/Part6Component.vue';
  import Part7Component from './Components/whatDo/Part7Component.vue';

  //resume scroll
  import ResumeScroll from './Components/ResumeScroll.vue';



  //promote time line
  import Promote1Component from './Components/TimeLinePromote/Promote1Component.vue';
  import Promote2Component from './Components/TimeLinePromote/Promote2Component.vue';
  import Promote3Component from './Components/TimeLinePromote/Promote3Component.vue';

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useCalculatorStore } from '@/stores/useCalculatorStore';
  const store = useCalculatorStore();


  onMounted(async () => {
    await store.fetchDossierData(route.params.domain, route.params.type);
    
  });


  const data = computed(() => store?.dossierData);

  const addNewDossier = (newDossier) => {
      store.dossierData.dossier_data.push(newDossier.data_dossier); 
  };


</script>
<style>

  .custom-span {
    color: inherit !important;
  }

  p,
  h1 {
    font-family: 'Rethink Sans', sans-serif !important;
    color: #333333 !important;
  }

  .slider-content {
    font-family: 'Rethink Sans', sans-serif !important;
  }

  .svg-black {
    filter: brightness(0) saturate(100%) invert(14%) sepia(2%) saturate(0%) hue-rotate(194deg) brightness(97%) contrast(89%);
  }

  .border-separation-mid {
    background-color: #333333;
    height: 3px !important;
  }

  .border-separation-mid-white {
    background-color: white;
    height: 3px;
  }

  @media (max-width: 1440px) {
    .max-w-[1680px] {
      max-width: 1200px;
    }
  }
</style>
