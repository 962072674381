<template>
    <div class="flex justify-end items-center gap-2 mt-[0px]">
        <TabSlider :data="data" :form="store.form" @selectData="selectData" />
    </div>
    <div :class="aloneClass">
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Sin</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
            <div class="col-span-3 grid grid-cols-4 gap-4">
                <!-- N° de habitaciones -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[48px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">N° de <br>habitaciones</label>
                    <input type="number" v-model.number="store.form.rooms" class="input-field-total no-arrow text-right mr-4" />
                </div>

                <!-- N° de habitaciones -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[48px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">N° de meses <br>abiertos</label>
                    <input type="number" v-model.number="store.form.openMonths" class="input-field-total no-arrow text-right mr-4" />
                </div>
                

                <!-- Precio medio por noche -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[18px] 2xl-1440:text-[13px] wider-label">Precio medio por noche</label>
                    <div class="flex items-center w-[45%] rounded-r-full">
                        <input type="tel" v-model.number="store.form.averagePrice" @input="store.form.averagePrice = parseInt(store.form.averagePrice) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix-total">€</p>
                    </div>
                </div>

                <!-- Tasa de ocupación -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Tasa de <br> ocupación</label>
                    <div class="flex items-center w-[45%] rounded-r-full">
                        <input type="number" v-model.number="store.form.occupancyRate" @input="store.form.occupancyRate = parseInt(store.form.occupancyRate) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix-total">%</p>
                    </div>
                </div>
            </div>

            <!-- Total de ingresos anuales -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[18px] 2xl-1440:text-[13px]">Total de ingresos anuales</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenue }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-12" :class="aloneClass">
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Con</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
            <div class="col-span-3 grid grid-cols-4 gap-4">
                <!-- Incremento en la reputación online -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[18px] 2xl-1440:text-[13px] wider-label-max-return whitespace-nowrap">Incremento nota <br>media en OTA</label>
                    <input type="number" v-model.number="store.form.reputationIncrease" step="0.0" class="input-field-total no-arrow text-right mr-4" />
                    <!-- <p class="input-suffix-total">%</p> -->
                </div>

                <!-- Índice de recurrencia -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Índice de <br>recurrencia</label>
                    <input type="number" v-model.number="store.form.recurrenceIndex" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">%</p>
                </div>

                <!-- Incremento en el precio medio por noche -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[18px] 2xl-1440:text-[13px] wider-label-max-increment whitespace-nowrap">Nuevo precio<br> medio por noche</label>
                    <div class="flex items-center rounded-r-full">
                        <input type="number" v-model.number="store.form.pricePerNightIncrease" @input="store.form.pricePerNightIncrease = parseInt(store.form.pricePerNightIncrease) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix-total">€</p>
                    </div>
                </div>

                <!-- Incremento en la tasa de ocupación -->
                <div class="flex items-center bg-[#FAFAFA] border-black border rounded-full-style">
                    <label class="input-label-black text-[18px] 2xl-1440:text-[13px] wider-label whitespace-nowrap">Nueva tasa <br> de ocupación</label>
                    <div class="flex items-center rounded-r-full">
                        <input type="number" v-model.number="store.form.occupancyRateIncrease" @input="store.form.occupancyRateIncrease = parseInt(store.form.occupancyRateIncrease) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix-total">%</p>
                    </div>
                </div>
            </div>

            <!-- Total de ingresos anuales con TheHoster -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[18px] 2xl-1440:text-[13px] wider-label-max flex flex-col">
                    Total de Ingresos<br>
                    <p class="flex items-center whitespace-nowrap">
                        anuales con
                        <img src="/img/presentacion/LogoSmallPNG.png" alt="Logo" class="ml-1 mt-0 w-[88px] h-[19px] inline-block align-middle" />
                    </p>
                </label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenueWithHoster }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>


        </div>

        <!-- Retorno Section -->
        <div class="mt-[46px] 2xl-1440:mt-[38px] flex flex-col items-center w-full">
            <div class="flex flex-col w-[600px]">
                <p class="text-[35px] font-semibold mb-[12px] self-start w-full max-w-[500px]">Retorno</p>
                <hr class="border-t-[3px] border-[#34A98F] w-full max-w-[600px] mb-[30px]" />
                <div class="flex justify-between items-center w-full max-w-[600px] mx-auto mt-[0px]">
                    <label class="text-left text-[24px] font-semibold">Incremento en<br> la facturación</label> 
                    <span class="text-[#1E6659] font-semibold text-[60px] leading-none" v-if="isFormComplete && increment > 0 || increment == 0">{{ increment > 0 || increment == 0 ? '+' : '' }}{{ formattedIncrement ?? 0 }}€</span>
                    <span class="text-[#1E6659] font-semibold text-[60px] leading-none" v-else>0€</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import {  computed, watch, ref, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import TabSlider from '@/components/TabSlider.vue';

    import { debounce } from 'lodash';

    import { useCalculatorStore } from '@/stores/useCalculatorStore';
    const store = useCalculatorStore();

    const route = useRoute();

    const aloneClass = computed(() => {
    return route.meta.alone ? 'px-[60px]' : '';
    });

    const props = defineProps({
        data: {
            type: Object,
            required: true
        }
    });


    const typeDossier = ref(route.params.type);
    
watch(
    () => [store.form.rooms, store.form.pricePerRoomPerMonth, store.form.implementationPrice],
    ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
      
        //console.log(pricePerRoomPerMonth, implementationPrice,typeDossier.value);
      
        if (rooms && pricePerRoomPerMonth !== null) {
            if(typeDossier.value == 'A'){
                store.form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + (parseFloat(implementationPrice) || 0));
            }else{
                store.form.investmentInHoster = Math.ceil( pricePerRoomPerMonth * 12 + (parseFloat(implementationPrice) || 0));
            }
            

        } else {
            store.form.investmentInHoster = 0;
        }

        console.log(store.form.investmentInHoster);
    },

    { immediate: true }
);






    // Estado reactivo para el tab activo

    const isUpdatingFromStore = ref(false);
    const isMounted = ref(false);

    setTimeout(() => {
        isMounted.value = true; 
    }, 100);

    onMounted(() => {
        //alert('onMounted');
        if (props.data.dossier_data.length == 1 || store.activeTab == null) {
            store.activeTab = props.data.dossier_data[0].tab_number;
        }

        selectData(store.activeTab);
        
    });

    const isFormComplete = computed(() => {
        return store.form.rooms !== 0 &&
               store.form.averagePrice !== 0 &&
               store.form.occupancyRate !== 0 &&
               store.form.reputationIncrease !== 0 &&
               store.form.pricePerNightIncrease !== 0 &&
               store.form.occupancyRateIncrease !== 0 &&
               store.form.openMonths !== 0;
    });

    const selectData = async (tab_number) => {
        store.activeTab = tab_number; 
        store.form.tab_number = tab_number;

        isUpdatingFromStore.value = true;

        // Esperar a que los datos lleguen antes de asignarlos
        await store.fetchDossierDatabyTab(tab_number);

        // Verificar si los datos llegaron correctamente
        if (store.dossierDatabyId) {   

            // Asignar valores al formulario
            Object.assign(store.form, {
                rooms: store.dossierDatabyId.rooms ?? 0,
                averagePrice: store.dossierDatabyId.averagePrice ?? 0,
                occupancyRate: store.dossierDatabyId.occupancyRate ?? 0,
                reputationIncrease: store.dossierDatabyId.reputationIncrease ?? 0,
                pricePerNightIncrease: store.dossierDatabyId.pricePerNightIncrease ?? 0,
                occupancyRateIncrease: store.dossierDatabyId.occupancyRateIncrease ?? 0,
                recurrenceIndex: store.dossierDatabyId.recurrenceIndex ?? 0,
                openMonths: store.dossierDatabyId.openMonths ?? 0,
                investmentInHoster: store.dossierDatabyId.investmentInHoster ?? 0,
            });
        } else {
            console.warn('No se encontraron datos para este tab_number:', tab_number);
        }

        setTimeout(() => {
            isUpdatingFromStore.value = false; 
        }, 100);
    };



    const debouncedUpdate = debounce((newValues) => {
        if (!isUpdatingFromStore.value && isMounted.value) { // 
            console.log(newValues);
            store.updateCalculatorData(newValues);
        }
    }, 600);

    watch(
        () => ({ ...store.form }),
        (newValues) => {
            if (!isUpdatingFromStore.value && isMounted.value) { 
                debouncedUpdate(newValues);
            }
        },
        { deep: true }
    );
    // Cálculo de "Total de ingresos anuales"
    /* const annualRevenue = computed(() => {
        return Math.ceil(store.form.rooms * store.form.averagePrice * (store.form.occupancyRate / 100) * 365 || 0);
    }); */

    const annualRevenue = computed(() => {
        return Math.ceil((store.form.rooms  * store.form.averagePrice ) * (store.form.openMonths * 31) * (store.form.occupancyRate / 100) || 0);
    });

    const formattedAnnualRevenue = computed(() => {
        return formatCurrency(annualRevenue.value);
    });


    const annualRevenueWithHoster = computed(() => { //fran
        if (
            store.form.rooms !== null &&
            store.form.pricePerNightIncrease !== null &&
            store.form.occupancyRateIncrease !== null
        ) {
            return Math.ceil(
                store.form.rooms * store.form.pricePerNightIncrease * (store.form.openMonths * 31) * (store.form.occupancyRateIncrease / 100) || 0
            );

        }
        return 0;
    });


    const formattedAnnualRevenueWithHoster = computed(() => {
        return formatCurrency(annualRevenueWithHoster.value);
    });

    // Cálculo de "Incremento en la facturación"
    const increment = computed(() => {
        if(store.form.annualRevenueWithHoster == 0 || store.form.annualRevenue == 0){
            return 0;
        }else{
            return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value);
        }
    });

    const formattedIncrement = computed(() => {
        return formatCurrency(increment.value);
    });


    watch(
        () => [store.form.rooms, store.form.pricePerRoomPerMonth, store.form.implementationPrice],
        ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
            if (rooms && pricePerRoomPerMonth !== null) {
                store.form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + (parseFloat(implementationPrice) || 0));
            } else {
                store.form.investmentInHoster = 0;
            }
        },
        { immediate: true }
    );

    /* const formattedInvestmentInHoster = computed(() => {
        return formatCurrency(store.form.investmentInHoster);
    }); */

    // Cálculo del beneficio
    const benefit = computed(() => {
        return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value - store.form.investmentInHoster) || 0;
    });

    /* const formattedBenefit = computed(() => {
        return formatCurrency(benefit.value);
    });

    // Cálculo del ROI
    const roi = computed(() => {
        const roiValue = ((annualRevenueWithHoster.value - annualRevenue.value - store.form.investmentInHoster) / store.form.investmentInHoster);
        if (isNaN(roiValue) || roiValue === Infinity || roiValue < 0) {
            return 0;
        }
        return roiValue;
    });

    const formattedROI = computed(() => {
        return roi.value.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }); */

    // Formatear el número como moneda con puntos
    function formatCurrency(value) {
        return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); // Eliminando los decimales
    }

    // Usamos un watch para actualizar los valores en form
    watch(annualRevenue, (newValue) => {
        store.form.annualRevenue = newValue;
    });

    watch(annualRevenueWithHoster, (newValue) => {
        store.form.annualRevenueWithHoster = newValue;
    });

    watch(benefit, (newValue) => {
        store.form.benefit = newValue;
    });




</script>


<style scoped>
/* Eliminar las flechas del input de tipo número */
.no-arrow::-webkit-inner-spin-button,
.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
label, span, p {
  font-family: 'Rethink Sans', sans-serif !important;
}

.no-arrow {
  -moz-appearance: textfield;
}

/* Sombra personalizada */
.custom-shadow {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
}

/* Redondeo completo aplicado en el contenedor */
.rounded-full-style {
    border-radius: 10px;
    height: 70px;
}

/* Estilo general para los labels y los inputs */
.input-label-black {
    padding: 15px 16px;
    font-weight: 600;
    color: #333333;
    height: 100%;
    font-size: 16px;
    background-color: #FFF;
    text-align: left;
    border-right: 1px solid black;
    border-radius: 10px 0 0 10px;
    display: flex; 
    align-items: center; 
}


.input-label {
    padding: 15px 11px;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    text-align: left;
    border-right: 1px solid #E9E9E9;
    border-radius: 9999px 0 0 9999px;
}

/* Clase específica para labels más anchos */
.wider-label {
    width: 56%;
}

.wider-label-max {
    width: 60%;
}

.wider-label-max-return {
    width: 80%;
}

.wider-label-max-increment {
    width: 80%;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.border-bottom-custom {
    border-bottom: 4px solid #34A98F;
}

.input-field-total {
    padding: 15px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    border-radius: 0 9999px 9999px 0;
    text-align: right;
    background-color: #FAFAFA;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-field {
    padding: 15px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    border-radius: 0 9999px 9999px 0;
    border: none;
    text-align: right;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-suffix-total {
    padding-left: 0px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

.input-suffix {
    padding-left: 0px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

input:focus {
    border: none !important;
    box-shadow: none !important;
}

.border-separation-mid {
    background-color: #333333;
    height: 3px;
    margin-bottom: 26px;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>


