<template>
  <div class="relative w-full h-screen overflow-hidden bg-[#FFFFFF]" @wheel="handleScroll">
    <!-- Fondo dinámico (solo si la slide lo requiere) -->
      <div v-if="currentSlide?.background" class="absolute inset-0 z-0 pointer-events-none">
        <!-- Imagen superior -->
        <div class="absolute top-0 left-0 w-full h-[200px]">
          <img src="/img/presentacion/GuardaPNG.png" alt="Top gradient" class="w-full h-full object-cover" />
          <div class="absolute inset-0 bg-gradient-to-l from-white"></div>
        </div>

        <!-- Imagen inferior -->
        <div class="absolute bottom-0 left-0 w-full h-[200px]">
          <img src="/img/presentacion/GuardaPNG.png" alt="Bottom gradient" class="w-full h-full object-cover" />
          <div class="absolute inset-0 bg-gradient-to-r from-white"></div>
        </div>
    </div>
    <div v-if="currentSlide && currentSlide.header" class="relative top-0 left-0 w-full z-20 bg-[#FFFFFF] pr-[120px] pl-[120px]">
      <div class="flex justify-between items-center pt-[24px] pb-[24px] pr-[40px]">
        <div class="flex items-center gap-2">
          <!-- Icono Back (solo si currentSlide.mockup es true) -->
          <svg v-if="currentSlide.mockup" @click="goToSlide(backSlide)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class="cursor-pointer hover:bg-[#E9E9E9] transition rounded-[10px]">
            <path d="M20 8L12 16L20 24" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h2 class="text-[24px] font-normal uppercase">{{ currentSlide.title }}</h2>
        </div>

        <div class="flex items-center gap-4">
          <!-- Botón Backoffice con icono -->
          <button 
            @click="goToSlide('slide-29')" 
            class="flex items-center gap-2 px-6 py-2 rounded-[10px] text-[#212121] bg-transparent hover:bg-[#E9E9E9] transition font-semibold text-[18px]"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12.1471 17.8988H21C21.5523 17.8988 22 17.4511 22 16.8988V14M12.1471 17.8988H3C2.44771 17.8988 2 17.4511 2 16.8988V14M12.1471 17.8988V22M16.5098 22H12.1471M7.39216 22H12.1471M22 14V3C22 2.44772 21.5523 2 21 2H3C2.44772 2 2 2.44772 2 3V14M22 14H2" stroke="#212121" stroke-width="2" stroke-linecap="round"/>
            </svg>
            Backoffice
          </button>

          <!-- Botón Webapp con icono -->
          <button 
            @click="goToSlide('slide-28')" 
            class="flex items-center gap-2 px-6 py-2 rounded-[10px] text-[#212121] bg-transparent hover:bg-[#E9E9E9] transition font-semibold text-[18px]"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 17.9873V20.2C6 21.1941 6.80589 22 7.8 22H16.2C17.1941 22 18 21.1941 18 20.2V17.9873M6 17.9873V3.8C6 2.80589 6.80589 2 7.8 2H16.2C17.1941 2 18 2.80589 18 3.8V17.9873M6 17.9873H18" stroke="#212121" stroke-width="2"/>
            </svg>
            Webapp
          </button>

          <img src="/img/1.TH.logo.svg" alt="Logo" class="h-[29px] object-contain" />
        </div>
      </div>
      <hr class="border-gray-300" />
    </div>



    <!-- slider fijo con el logo central -->
    <div
      v-if="currentIndex === 0"
      class="relative flex items-center justify-center bg-[#FFFFFF] w-full h-screen mx-auto"
    >
      <div class="absolute top-0 w-full">
        <img src="/img/presentacion/GuardaPNG.png" alt="Imagen con degradado a la derecha" class="w-full object-cover">
        <div class="absolute inset-0 bg-gradient-to-l from-white"></div>
      </div>
      
      <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[152px] object-contain my-4 z-10" />

      <div class="absolute bottom-0 w-full">
        <img src="/img/presentacion/GuardaPNG.png" alt="Imagen con degradado a la izquierda" class="w-full object-cover">
        <div class="absolute inset-0 bg-gradient-to-r from-white"></div>
      </div>
    </div>

    <!-- Diapositiva dinámica -->
    <!-- <transition :name="transitionName" mode="out-in">
      <div
        v-if="currentSlide"
        :key="currentSlide.name"
        class="relative flex flex-col justify-start bg-[#FFFFFF] mx-auto"
        :style="{ height: currentSlide.fullHeight ? '100vh' : '780px', marginTop: currentSlide.fullHeight ? '0' : currentSlide.marginT ? currentSlide.marginT : '52px' }"
        :class="{'w-full': currentSlide.fullWidth, 'max-w-[1680px]': !currentSlide.fullWidth}"
      >

        <slot :name="currentSlide.name"></slot>
      </div>
    </transition> -->

    <transition :name="transitionName" mode="out-in">
        <div
          v-if="currentSlide"
          :key="currentSlide.name"
          class="relative flex flex-col justify-start mx-auto
                "
          :style="{
            height: currentSlide.fullHeight ? '100vh' : '780px',
            marginTop: currentSlide.fullHeight ? '0' : currentSlide.marginT ? currentSlide.marginT : '52px'
          }"
          :class="{
            'w-full': currentSlide.fullWidth,
            'max-w-[1680px] between-1440-1919:max-w-[1380px]' : !currentSlide.fullWidth,
          }"
        >
          <slot :name="currentSlide.name"></slot>
        </div>

    </transition>

    <div 
      v-if="currentIndex !== 0 && !currentSlide.fullHeight" 
      class="fixed right-[40px] bottom-[50px] flex  z-30 "
    >
      <button
        @click="prevSlide"
        :class="[isFirstSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-l-lg border border-[#E9E9E9] focus:outline-none']"
        :disabled="isFirstSlide"
      >
        <img src="/img/presentacion/up.svg" alt="Up" class="w-8 h-8" />
      </button>
      <button
        @click="nextSlide"
        :class="[isLastSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-r-lg border border-[#E9E9E9] focus:outline-none']"
        :disabled="isLastSlide"
      >
        <img src="/img/presentacion/down.svg" alt="Down" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps({
  useScroll: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: true,
  }
});

const currentIndex = ref(0);
const transitionName = ref('slide-up-down');

// Definición de las diapositivas, incluyendo `TablePrice` y `TablePriceShow`
const dynamicSlides = [
  { name: 'slide-1', header: true, layout: 'row', title: 'CALCULADORA DE INGRESOS', fullWidth: false, fullHeight: false, marginT: '24px'  },
   { name: 'slide-2', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-3', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-4', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-5', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-6', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-7', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-8', header: true, layout: 'col', title: 'qué hacemos', fullWidth: false, fullHeight: false, marginT: '32px' },
  { name: 'slide-9', header: true, layout: 'col', title: '¿cómo lo hacemos?', fullWidth: false, fullHeight: false, marginT: '32px' },
  //{ name: 'slide-10', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: true, fullHeight: true }, 
  { name: 'slide-10', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, background: true ,marginT: '22px' }, //hotel
  { name: 'slide-11', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, marginT: '22px', background: true }, //checkin
  { name: 'slide-12', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, background: true, marginT: '22px' }, //destino
  { name: 'slide-13', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, background: true, marginT: '22px' }, //servicios
  { name: 'slide-14', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, background: true, marginT: '22px' }, //reputacion
  { name: 'slide-15', header: true, layout: 'col', title: '¿cómo lo hacemos? - webapp', fullWidth: false, fullHeight: false, background: true, marginT: '22px' }, //fidelizacion
  //{ name: 'slide-16', header: true, layout: 'col', title: '¿cómo lo hacemos? - Promociona tu webapp', fullWidth: true, fullHeight: false,marginT: '0px' },//timeline
  { name: 'slide-17', header: true, layout: 'col', title: '¿cómo lo hacemos? - Promociona tu webapp', fullWidth: true, fullHeight: false,marginT: '0px' }, //timeline 2
  { name: 'slide-18', header: true, layout: 'col', title: '¿cómo lo hacemos? - Promociona tu webapp', fullWidth: true, fullHeight: false,marginT: '0px' }, //timeline 3
  { name: 'slide-19', header: true, layout: 'col', title: '¿cómo lo hacemos? - Promociona tu webapp', fullWidth: true, fullHeight: false,marginT: '0px' }, //timeline 4
  { name: 'slide-20', header: true, layout: 'col', title: '¿cómo lo hacemos? - comunicaciones', fullWidth: true, fullHeight: false,marginT: '-5px' }, //timeline email
  { name: 'slide-21', header: true, layout: 'col', title: '¿cómo lo hacemos? - backoffice', fullWidth: true, fullHeight: true }, 
  { name: 'slide-22', header: true, layout: 'col', title: 'resumen', fullWidth: true, fullHeight: true, marginT: '0px' ,background: true},
  { name: 'slide-23', header: true, layout: 'col', title: 'precio', fullWidth: false, fullHeight: false, marginT: '22px' },
  { name: 'slide-24', header: true, layout: 'col', title: 'precio', fullWidth: false, fullHeight: false, marginT: '22px' },
  { name: 'slide-25', header: true, layout: 'col', title: 'precio', fullWidth: false, fullHeight: false, marginT: '22px' },
  { name: 'slide-26', header: true, layout: 'row', title: 'CALCULADORA DE BENEFICIO', fullWidth: false, fullHeight: true   }, //antes 118px
  { name: 'slide-27', header: true, layout: 'row', title: 'siguientes pasos', fullWidth: false, fullHeight: false, marginT: '115px' }, //antes 118px
  { name: 'slide-28', header: true, layout: 'col', title: 'Prototipo webapp', fullWidth: true, fullHeight: true ,mockup: true, marginT: '142px' , background: true},
  { name: 'slide-29', header: true, layout: 'col', title: 'Prototipo Backoffice', fullWidth: true, fullHeight: true ,mockup: true, background: true },

  /* { name: 'slide-4', header: true, layout: 'col', title: 'PRICING', fullWidth: true, fullHeight: false },
  { name: 'slide-5', header: true, layout: 'col', title: 'PRICING', fullWidth: true, fullHeight: false }, */
];

const shortMode = ref(false);
const backSlide = ref(false);

const filteredSlides = computed(() => {
  return shortMode.value ? dynamicSlides.slice(0, 3) : dynamicSlides;
});

const isFirstSlide = computed(() => currentIndex.value === 0);
const isLastSlide = computed(() => currentIndex.value === filteredSlides.value.length);
const currentSlide = computed(() => filteredSlides.value[currentIndex.value - 1] || null);

// Obtener índice de la diapositiva
const getSlideIndex = (slideName) => dynamicSlides.findIndex(slide => slide.name === slideName) + 1;

// Función para cambiar a una diapositiva específica
const goToSlide = (slideName) => {
  const newIndex = getSlideIndex(slideName);

  // Solo guarda el backSlide si vienes desde una slide que NO es la 28 o 29
  if (
    currentSlide.value?.name !== 'slide-28' &&
    currentSlide.value?.name !== 'slide-29'
  ) {
    backSlide.value = currentSlide.value?.name;
  }

  if (newIndex > 0) {
    transitionName.value = 'slide-up-down';
    currentIndex.value = newIndex;
  }
};


// Modificación solo en prevSlide
/* const prevSlide = () => {
  if (currentSlide.value?.name === 'slide-5') {
    transitionName.value = ''; // Sin transición al saltar `TablePriceShow`
    currentIndex.value -= 2; // Retrocede directamente a `TablePrice`
  } else if (currentIndex.value > 0) {
    transitionName.value = 'slide-down-up';
    currentIndex.value--;
  }
}; */

const prevSlide = () => {
  if (currentSlide.value?.name === 'slide-25' || currentSlide.value?.name === 'slide-24'  || currentSlide.value?.name === 'slide-8' || currentSlide.value?.name === 'slide-7' || currentSlide.value?.name === 'slide-6' || currentSlide.value?.name === 'slide-5' || currentSlide.value?.name === 'slide-4' || currentSlide.value?.name === 'slide-3' || currentSlide.value?.name === 'slide-20' || currentSlide.value?.name === 'slide-19' || currentSlide.value?.name === 'slide-18' || currentSlide.value?.name === 'slide-17' || currentSlide.value?.name === 'slide-15') {
    // Retroceder desde `TablePriceShow` a `TablePrice`, sin transición
    transitionName.value = ''; 
    currentIndex.value--;
  } else if (currentSlide.value?.name === 'slide-23') {
    // Retroceder desde `TablePrice` al componente anterior con transición
    transitionName.value = 'slide-down-up'; // Restablece la transición
    currentIndex.value--;
  } else if (currentIndex.value > 0) {
    // Transición estándar de retroceso
    transitionName.value = 'slide-down-up';
    currentIndex.value--;
  }
};



// NextSlide tal cual fue proporcionado
/* const nextSlide = () => {
  if(currentSlide.value?.name == 'slide-11') {
    return false;
  }
  console.log(filteredSlides.value.length, currentIndex.value + 1,currentSlide.value?.name);
  // Verificamos si estamos en `TablePrice` y `TablePriceShow` para desactivar la transición general
  //if (currentSlide.value?.name === 'slide-2' && currentIndex.value + 1 < filteredSlides.value.length) {
  if (currentSlide.value?.name === 'slide-4') {
      transitionName.value = ''; // Sin transición entre TablePrice y TablePriceShow
      currentIndex.value++;
      //alert('entra');
  } else {
    transitionName.value = 'slide-up-down';
    currentIndex.value++;
  }
}; */

let oldIndex;
const nextSlide = () => {
  oldIndex = currentIndex.value;
  const newIndex = currentIndex.value + 1;

  // if end slider
  if (filteredSlides.value[oldIndex - 1]?.name === 'slide-27') {
    return false;
  }

  // Verificamos si pasamos de la slide-2 a la slide-3 (por ejemplo)
  if (
    filteredSlides.value[oldIndex - 1]?.name === 'slide-2' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-3' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-3' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-4' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-4' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-5' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-5' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-6' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-6' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-7' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-7' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-8' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-16' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-17' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-17' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-18' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-18' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-19' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-19' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-20' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-23' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-24' ||
    filteredSlides.value[oldIndex - 1]?.name === 'slide-24' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-25' 
    /* filteredSlides.value[oldIndex - 1]?.name === 'slide-12' &&
    filteredSlides.value[newIndex - 1]?.name === 'slide-13' */
  ) {
    // Sin transición
    transitionName.value = '';
    currentIndex.value++;
  } else {
    // Transición normal
    transitionName.value = 'slide-up-down';
    currentIndex.value++;
  }

  currentIndex.value = newIndex;
};

let isScrolling = false;

const handleScroll = (event) => {
  if (props.useScroll && !isScrolling) {
    if (currentSlide.value?.mockup) {
      return; 
    }
    isScrolling = true;
    if (event.deltaY < 0) {
      prevSlide();
    } else if (event.deltaY > 0) {
      nextSlide();
    }
    setTimeout(() => {
      isScrolling = false;
    }, 620);
  }
};

const handleKeydown = (event) => {
  //console.log(event.key,currentSlide.value);
  if (event.key === 'ArrowUp' || event.key === 'ArrowLeft' && currentSlide.value?.name !== 'slide-1' && currentSlide.value?.name !== 'slide-26') {
    prevSlide();
  } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight' && currentSlide.value?.name !== 'slide-1' && currentSlide.value?.name !== 'slide-26') {
    nextSlide();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);

  const queryParams = new URLSearchParams(window.location.search);
  shortMode.value = queryParams.get('short') === '1';
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});

</script>

<style scoped>
/* Animación cuando avanzamos (Down), la página sube */
.slide-up-down-enter-active, .slide-up-down-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-up-down-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-down-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Animación cuando retrocedemos (Up), la página baja */
.slide-down-up-enter-active, .slide-down-up-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-down-up-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
