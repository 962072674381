<template>
    <div :class="aloneClass">
        <div class="flex justify-end items-center gap-2">
            <TabSlider :data="data" :form="store.form" @selectData="selectData" />
        </div>
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Sin</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
            <div class="col-span-3 grid grid-cols-4 gap-4">
                <!-- N° de habitaciones -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">N° de <br>habitaciones</label>
                    <input type="number" v-model.number="store.form.rooms" class="input-field-total no-arrow text-right mr-4" />
                </div>

                <!-- meses abiertos -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">N° de meses <br>abiertos</label>
                    <input type="number" v-model.number="store.form.openMonths" class="input-field-total no-arrow text-right mr-4" />
                </div>

                <!-- Precio medio por noche -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio medio<br> por noche</label>
                    <div class="flex items-center w-[45%] rounded-r-full">
                        <input type="number" v-model.number="store.form.averagePrice" @input="store.form.averagePrice = parseInt(store.form.averagePrice) || ''" class="input-field-total no-arrow text-right " />
                        <p class="input-suffix">€</p>
                    </div>
                </div>

                <!-- Tasa de ocupación -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Tasa de ocupación</label>
                    <div class="flex items-center w-[45%] rounded-r-full">
                        <input type="number" v-model.number="store.form.occupancyRate" @input="store.form.occupancyRate = parseInt(store.form.occupancyRate) || ''" class="input-field-total no-arrow text-right " />
                        <p class="input-suffix">%</p>
                    </div>
                </div>
            </div>

            <!-- Total de ingresos anuales -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[20px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Total de ingresos <br>anuales</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenue }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-12" :class="aloneClass">
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Con</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <p class="text-[24px] font-semibold mb-[6px] 2xl-1440:text-[20px] h-[40px] px-[60px]">Ingresos</p>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
           <div class="col-span-3 grid grid-cols-4 gap-4">
                <!-- Incremento en la reputación online -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Incremento nota<br> media en OTA</label>
                    <input type="number" v-model.number="store.form.reputationIncrease" step="0.0" class="input-field-total no-arrow text-right mr-4" />
                    <!-- <p class="input-suffix">%</p> -->
                </div>
                <!-- Índice de recurrencia -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Índice de <br>recurrencia</label>
                    <input type="number" v-model.number="store.form.recurrenceIndex" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">%</p>
                </div>

                <!-- Incremento en el precio medio por noche -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Nuevo precio <br>medio por noche</label>
                    <div class="flex items-center rounded-r-full">
                        <input type="number" v-model.number="store.form.pricePerNightIncrease" @input="store.form.pricePerNightIncrease = parseInt(store.form.pricePerNightIncrease) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix">€</p>
                    </div>
                </div>

                <!-- Incremento en la tasa de ocupación -->
                <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                    <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Nueva <br>tasa de ocupación</label>
                    <div class="flex items-center rounded-r-full">
                        <input type="number" v-model.number="store.form.occupancyRateIncrease" @input="store.form.occupancyRateIncrease = parseInt(store.form.occupancyRateIncrease) || ''" class="input-field-total no-arrow text-right no-padding-right" />
                        <p class="input-suffix">%</p>
                    </div>
                </div>
            </div>

            <!-- Total de ingresos anuales con TheHoster -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[18px] 2xl-1440:text-[13px] w-3/5 flex flex-col">
                    Total de Ingresos<br>
                    <p class="flex items-center whitespace-nowrap">
                        anuales con
                        <img src="/img/presentacion/LogoSmallPNG.png" alt="Logo" class="ml-1 mt-0 w-[95px] h-[20px] inline-block align-middle" />
                    </p>
                </label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenueWithHoster }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>

        </div>
        <p class="text-[24px] font-semibold mb-[6px] mt-[16px] 2xl-1440:text-[20px] h-[35px] px-[60px]">Costes</p>
        <div class="flex gap-4 px-[60px]">
            <!-- Precio por habitación al mes -->
            <div v-if="typeDossier == 'A'" class="flex items-center relative bg-[#FAFAFA] border border-black rounded-full-style">
                <transition
                    name="fade-slide-up"
                    appear
                >
                    <div v-if="ShowDiscountA" class="absolute -top-[29px] right-[12px] bg-[#34A98F] text-[#FFF]  px-2 py-[2px] w-[202px] rounded-t-md shadow-md font-semibold text-[16px] text-center">
                        {{ valueDiscountA }}% de Descuento
                    </div>
                </transition>
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio por habitación al mes</label>

                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="store.form.pricePerRoomPerMonth" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>
            <div v-else class="flex items-center relative bg-[#FAFAFA] border border-black rounded-full-style">
                <transition
                    name="fade-slide-up"
                    appear
                >
                    <div v-if="ShowDiscountB" class="absolute -top-[29px] right-[12px] bg-[#34A98F] text-[#FFF]  px-2 py-[2px] w-[202px] rounded-t-md shadow-md font-semibold text-[16px] text-center">
                        {{ valueDiscountB }}% de Descuento
                    </div>
                </transition>
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap"> Coste mensual del servicio</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="store.form.pricePerRoomPerMonth" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!--Precio de implementación-->
            <div class="flex items-center bg-[#FAFAFA] border border-black relative rounded-full-style">
                <transition
                    name="fade-slide-up"
                    appear
                >
                    <div v-if="ShowDiscountImplementation" class="absolute -top-[29px] right-[12px] bg-[#34A98F] text-[#FFF]  px-2 py-[2px] w-[202px] rounded-t-md shadow-md font-semibold text-[16px] text-center">
                        {{ valueDiscountImplementation }}% de Descuento
                    </div>
                </transition>
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio de implementación</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="store.form.implementationPrice" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Inversión en TheHoster -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow w-[30%]">
                <label class="input-label text-[18px] 2xl-1440:text-[13px] w-3/5 flex items-center whitespace-nowrap">
                    Inversión en
                    <img src="/img/presentacion/LogoSmallPNG.png" alt="Logo" class="ml-1 mt-0 w-[95px] h-[20px] inline-block align-middle" />
                </label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px]  h-full">
                    <p class="input-field">{{ formattedInvestmentInHoster }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
       
        <!-- Retorno Section -->
        <div class="mt-[48px] 2xl-1440:mt-[38px] flex flex-col items-center w-full">
            <div class="flex flex-col w-full max-w-[1680px]">
                <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mb-[12px] self-start w-full max-w-[500px]">Retorno</p>
                <hr class="border-t-[3px] border-[#34A98F] w-full max-w-[1680px] mb-[40px]" />
                <div class="grid grid-cols-3 gap-[124px] w-full max-w-[1680px] mx-auto mt-[0px]">
                    <!-- Incremento en la facturación -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Incremento en<br> la facturación</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ increment > 0 || increment == 0 ? '+' : '' }}{{ formattedIncrement ?? 0 }}€</span>
                    </div>

                    <!-- Beneficio -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Beneficio</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ formattedBenefit > 0 || formattedBenefit == 0 ? '+' : '' }}{{ formattedBenefit ?? 0 }}€</span>
                    </div>

                    <!-- ROI -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Retorno</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ formattedROI ?? 0 }}x</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {  computed, watch,onMounted,ref } from 'vue';
import { useRoute } from 'vue-router';
import TabSlider from '@/components/TabSlider.vue';
import { debounce } from 'lodash';

import { useCalculatorStore } from '../stores/useCalculatorStore';
import { useDiscountCalculator } from '@/composables/useDiscountCalculator';
const store = useCalculatorStore();

const route = useRoute();

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
});

const typeDossier = ref(route.params.type);

const aloneClass = computed(() => {
  return route.meta.alone ? 'px-[60px]' : '';
});


const isUpdatingFromStore = ref(false);
const isMounted = ref(false);

const originalPrice = ref(0);

const basePriceA = 17.99;
const basePriceB = 599;

const basePriceImplementation = 900;

setTimeout(() => {
    isMounted.value = true; 
}, 100);



const selectData = async (tab_number) => {
    store.activeTab = tab_number; 
    store.form.tab_number = tab_number;


    isUpdatingFromStore.value = true;

    // Esperar a que los datos lleguen antes de asignarlos
    await store.fetchDossierDatabyTab(tab_number);

    // Verificar si los datos llegaron correctamente
    if (store.dossierDatabyId) {
        
        // Asignar valores al formulario
        Object.assign(store.form, {
            rooms: store.dossierDatabyId.rooms ?? 0,
            averagePrice: store.dossierDatabyId.averagePrice ?? 0,
            occupancyRate: store.dossierDatabyId.occupancyRate ?? 0,
            reputationIncrease: store.dossierDatabyId.reputationIncrease ?? 0,
            pricePerNightIncrease: store.dossierDatabyId.pricePerNightIncrease ?? 0,
            occupancyRateIncrease: store.dossierDatabyId.occupancyRateIncrease ?? 0,
            recurrenceIndex: store.dossierDatabyId.recurrenceIndex ?? 0,
            openMonths: store.dossierDatabyId.openMonths ?? 0,
            implementationPrice: store.dossierDatabyId.implementationPrice,
        });
        originalPrice.value = store.form.pricePerRoomPerMonth;
    } else {
        console.warn('No se encontraron datos para este tab_number:', tab_number);
    }

    setTimeout(() => {
        isUpdatingFromStore.value = false; 
    }, 100);
};

const debouncedUpdate = debounce((newValues) => {
  if (!isUpdatingFromStore.value && isMounted.value) { // 
    store.updateCalculatorData(newValues);
  }
}, 600);

watch(
  () => ({ ...store.form }),
  (newValues) => {
    if (!isUpdatingFromStore.value && isMounted.value) { 
      debouncedUpdate(newValues);
    }
  },
  { deep: true }
);

// Cálculo de "Total de ingresos anuales"
const annualRevenue = computed(() => {
    return Math.ceil((store.form.rooms  * store.form.averagePrice ) * (store.form.openMonths * 31) * (store.form.occupancyRate / 100) || 0);
});

const formattedAnnualRevenue = computed(() => {
    return formatCurrency(annualRevenue.value);
});


const annualRevenueWithHoster = computed(() => {
    if (
        store.form.rooms !== null &&
        store.form.pricePerNightIncrease !== null &&
        store.form.occupancyRateIncrease !== null
    ) {

        return Math.ceil(
            store.form.rooms * store.form.pricePerNightIncrease * (store.form.openMonths * 31) * (store.form.occupancyRateIncrease / 100) || 0
        );


    }
    return 0;
});


const formattedAnnualRevenueWithHoster = computed(() => {
    return formatCurrency(annualRevenueWithHoster.value);
});

// Cálculo del ROI
/* const roi = computed(() => {
    const roiValue = ((annualRevenueWithHoster.value - annualRevenue.value - store.form.investmentInHoster) / store.form.investmentInHoster);
    if (isNaN(roiValue) || roiValue === Infinity || roiValue < 0) {
        return 0;
    }
    return roiValue;
}); */

//rio is incnremento facturacion / inversion en thehoster
const roi = computed(() => {
    const roiValue = ((Math.ceil(annualRevenueWithHoster.value - annualRevenue.value)) / store.form.investmentInHoster);
    if (isNaN(roiValue) || roiValue === Infinity || roiValue < 0) {
        return 0;
    }
    return roiValue;
});

const formattedROI = computed(() => {
    return roi.value.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
});



// Cálculo de "Incremento en la facturación"
const increment = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value);
    //return (store.form.rooms * store.form.averagePrice * store.form.occupancyRate * 31) * store.form.openMonths ?? 0;
});

const formattedIncrement = computed(() => {
    return formatCurrency(increment.value);
});


watch(
    () => [store.form.rooms, store.form.pricePerRoomPerMonth, store.form.implementationPrice],
    ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
        //console.log(rooms, pricePerRoomPerMonth, implementationPrice,typeDossier.value);
        if (rooms && pricePerRoomPerMonth !== null) {
            if(typeDossier.value == 'A'){
                store.form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + (parseFloat(implementationPrice) || 0));
            }else{
                store.form.investmentInHoster = Math.ceil( pricePerRoomPerMonth * 12 + (parseFloat(implementationPrice) || 0));
            }
            

        } else {
            store.form.investmentInHoster = 0;
        }
    },

    { immediate: true }
);

const formattedInvestmentInHoster = computed(() => {
    return formatCurrency(store.form.investmentInHoster);
});

// Cálculo del beneficio
const benefit = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value - store.form.investmentInHoster) || 0;
});

const formattedBenefit = computed(() => {
    return formatCurrency(benefit.value);
});



// Formatear el número como moneda con puntos
function formatCurrency(value) {
    return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); // Eliminando los decimales
}

// Usamos un watch para actualizar los valores en form
watch(annualRevenue, (newValue) => {
    store.form.annualRevenue = newValue;
});


watch(annualRevenueWithHoster, (newValue) => {
    store.form.annualRevenueWithHoster = newValue;
});


watch(benefit, (newValue) => {
    store.form.benefit = newValue;
});



    onMounted(() => {
        //alert('onMounted');
        if (props.data.dossier_data.length == 1 || store.activeTab == null) {
            store.activeTab = props.data.dossier_data[0].tab_number;
        }

        selectData(store.activeTab);
        
    });

const {
  valueDiscountA,
  valueDiscountB,
  ShowDiscountA,
  ShowDiscountB,
  valueDiscountImplementation,
  ShowDiscountImplementation
} = useDiscountCalculator(store, basePriceA, basePriceB, basePriceImplementation);


</script>
<style scoped>
   @import url('ttps://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');
    /* Eliminar las flechas del input de tipo número */
    .no-arrow::-webkit-inner-spin-button,
    .no-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    label,span,p {
    font-family: 'Rethink Sans', sans-serif !important;
    }


    .no-arrow {
    -moz-appearance: textfield;
    }

    /* Sombra personalizada */
    .custom-shadow {
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
    }

    /* Redondeo completo aplicado en el contenedor */
    .rounded-full-style {
        border-radius: 10px;
        height: 70px;
        
    }

    .input-label-black {
        padding: 15px 11px;
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        height: 100%;
        background-color: #FFF;
        text-align: left;
        border-right: 1px solid black;
        border-radius: 10px 0 0 10px;
        display: flex; /* Asegura que el contenido esté en un contenedor flex */
        align-items: center; /* Centra el texto verticalmente */
    }


    /* Estilo general para los labels y los inputs */
    .input-label {
        padding: 12px 11px;
        font-size: 18px;
        height: 70px;
        font-weight: 600;
        color: #333333;
        text-align: left;
        border-right: 1px solid #E9E9E9;

        border-radius: 9999px 0 0 9999px;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    .input-black-label-border {
        border-right: 1px solid black !important;
    }

    /* Clase específica para labels más anchos */
    .wider-label {
        width: 56%; /* Ajusta este ancho según lo necesites */
    }

    .wider-label-max {
        width: 60%; /* Ajusta este ancho según lo necesites */
    }

    .wider-label-max-return {
        width: 80%; /* Ajusta este ancho según lo necesites */
    }

    .wider-label-max-increment {
        width: 80%; /* Ajusta este ancho según lo necesites */
    }

    /* Asegurar que el label no se rompa en varias líneas */
    .whitespace-nowrap {
        white-space: nowrap;
    }

    .border-bottom-custom {
        border-bottom: 4px solid #34A98F;
    }

    .input-field {
        padding: 15px 0; /* Eliminado el padding derecho */
        font-size: 20px;
        font-weight: 600;
        line-height: 130%;
        width: 100%;
        border-radius: 0 9999px 9999px 0;
        border: none;
        text-align: right;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    .input-field-total {
        padding: 15px 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 130%;
        width: 100%;
        border-radius: 0 9999px 9999px 0;
        background-color: #FAFAFA;
        text-align: right;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    .input-field-total-return {
        font-size: 20px;
        font-weight: 600;
        line-height: 130%;
        width: 100%;
        text-align: right;
        color: white;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    /* Clase adicional para eliminar el padding derecho */
    .no-padding-right {
        padding-right: 0;
    }

    .input-suffix {
        padding-left: 12px;
        padding-right: 8px;
        font-size: 20px;
        font-weight: 600;
        margin-left: -12px; /* Ajuste para compensar el padding del input */
        color: #333333;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    .input-suffix-return {
        padding-left: 12px;
        padding-right: 8px;
        font-size: 20px;
        font-weight: 500;
        margin-left: -12px; /* Ajuste para compensar el padding del input */
        color: white;
        font-family: 'Rethink Sans', sans-serif !important;
    }

    /* Eliminar el borde y sombra en el focus del input */
    input:focus {
        border: none !important;
        box-shadow: none !important;
    }

    .border-separation-mid {
        background-color: #333333;
        height: 3px;
        margin-bottom: 16px;
    }

    .border-separation-roi {
        background-color: #34A98F;
        height: 3px;
        margin-bottom: 16px;
    }

    .fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: all 0.3s ease;
}
.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.fade-slide-up-enter-to,
.fade-slide-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

</style>

