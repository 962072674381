import { defineStore } from 'pinia';
import api from '@/services/api';
import { useRoute } from 'vue-router';
import router from '@/router';


export const useCalculatorStore = defineStore('calculator', {
  state: () => {

   /*  const route = useRoute(); */
    
    return {
      rooms: 0,
      averagePrice: 0,
      occupancyRate: 0,
      reputationIncrease: 0,
      pricePerNightIncrease: 0,
      occupancyRateIncrease: 0,
      openMonths: 0,
      recurrenceIndex: 0,
      route : useRoute(),
      implementationPrice: 0,
      investmentInHoster: 0,
      benefit: 0,

      
      activeTab: null,
      form: {
        rooms: 0,
        averagePrice: 0,
        occupancyRate: 0,
        reputationIncrease: 0.0,
        pricePerNightIncrease: 0,
        occupancyRateIncrease: 0,
        pricePerRoomPerMonth : 0,
        openMonths: 0,
        implementationPrice: 0,
        annualRevenue: 0,
        annualRevenueWithHoster: 0,
        recurrenceIndex: 0,
        investmentInHoster: 0,
        benefit: 0,
        dossier_id: null,
      },
      dossierData: null,
      dossierDatabyId: null,
    };

  },
  actions: {
    // Actualiza el formulario centralizado
    updateForm(data) {
      Object.assign(this.form, data);
    },

    // Establece la pestaña activa
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    // Actualiza datos generales y guarda en base de datos
    updateCalculatorData(data, tab_number) {
      Object.assign(this, data);
      this.saveToDatabase(data, tab_number);
    },

    async saveToDatabase(data, tab_number) {
      try {
        const response = await api.post('/dossier/storeUpdateOrCreate', data, tab_number);
        this.dossierData = response.data;
      } catch (error) {
        console.error('Error al guardar los datos:', error);
      }
    },

    // Obtiene los datos del dossier
    async fetchDossierData(domain, type) {
      try {
        const response = await api.get(`/dossier/getDossier/${domain}/${type}`);
        if(response.status == 204){
          router.push('/');
          return;
        }
        this.dossierData = response.data;

        this.form.dossier_id = response.data.id;


      } catch (error) {
        console.error('Error al obtener los datos del dossier:', error);
        this.dossierData = null;
      }

    },

    // Obtiene los datos específicos de una pestaña
    async fetchDossierDatabyTab(tab_number) {
      try {
        
        const response = await api.get(`/dossier/getDossierData/${tab_number}`);
        this.dossierDatabyId = response.data;
        this.activeTab = tab_number;
        this.form.implementationPrice = response.data.implementationPrice;
        this.form.pricePerRoomPerMonth = response.data.pricePerRoomPerMonth;

        //save localstorage
        localStorage.setItem('dossierDatabyId', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error al obtener los datos del dossier:', error);
        this.dossierDatabyId = null;
      }
    },

    // Crea un nuevo escenario y establece el activeTab según corresponda
    async addNewScenario(form) {
      try {
        const response = await api.post('/dossier/createNewScenario', { ...form });
        this.dossierData = response.data;
        const dossierData = response.data.dossier_data;
        this.activeTab = dossierData.length > 1 
          ? dossierData[dossierData.length - 1].tab_number 
          : dossierData[0].tab_number;

        form.tab_number = this.activeTab;
      } catch (error) {
        console.error('Error al crear un nuevo escenario:', error);
      }
    }
  },
});
