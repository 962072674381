<template>
    <!-- <div class="h-32 border-l-2 border-red-500 bottom-[450px] absolute z-10"></div> -->
    <!-- line email de reserva -->
    <div class="absolute h-[75px] ">
      <div class="absolute left-[163px] top-[270px] h-full w-2 bg-[#34A98F]"></div>
    </div>
    <div class="p-4 bg-[#34A98F] w-[369px] h-[63px] rounded-[20px] absolute left-[125px] top-[330px] flex items-center justify-center z-[500]">
      <h3 class="text-[24px] font-semibold text-white">E-mail de la reserva</h3>
    </div>
    <img src="/img/presentacion/timeline/pms.svg" alt="Slide 10" class="w-[408px] h-[200px] left-[105px] top-[350px] absolute">

    <!-- line Señalética -->
    <div class="absolute h-[75px] ">
      <div class="absolute left-[755px] top-[270px] h-full w-2 bg-[#34A98F]"></div>
    </div>
    <div class="p-4 bg-[#34A98F] w-[426px] h-[63px] rounded-[20px] absolute left-[600px] top-[330px] flex items-center justify-center z-[500]">
      <h3 class="text-[24px] font-semibold text-white">Señalética</h3>
    </div>
    <img src="/img/presentacion/senalitica.png" alt="Slide 10" class="w-[482px] h-[428px]  left-[572px] top-[360px] absolute">

     <!-- line Invitación entre huéspedes -->
     <div class="absolute h-[75px] ">
      <div class="absolute left-[1170px] top-[270px] h-full w-2 bg-[#34A98F]"></div>
    </div>
    <div class="p-4 bg-[#34A98F] w-[426px] h-[63px] rounded-[20px] absolute left-[1140px] top-[330px] flex items-center justify-center z-[500]">
      <h3 class="text-[24px] font-semibold text-white">Invitación entre huéspedes</h3>
    </div>
    <img src="/img/presentacion/timeline/huesped.svg" alt="Slide 10" class="w-[485px] h-[173px]  left-[1114px] top-[350px] absolute">

    
    <img src="/img/presentacion/timeline/2.svg" alt="Slide 7" class="w-full top-0 absolute z-10">
</template>