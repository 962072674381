import axios from 'axios';

// Obtén la URL actual del proyecto
//const currentURL = window.location.href;

// Define la condición para determinar si se está en ambiente de test.
// Por ejemplo, si la URL contiene "test.thehoster.io". Ajusta la condición según tu necesidad.
//const isTestEnvironment = currentURL.includes('test.thehoster.io');

// Selecciona la URL base y la clave API según el ambiente detectado
const baseURL = process.env.VUE_APP_API_URL_BACKEND_GENERAL;   // URL para ambiente principal

const xKeyApi = process.env.VUE_APP_X_KEY_API;    // Key para ambiente principal

// Configuración de la instancia de Axios
const api = axios.create({
  baseURL, // URL base determinada dinámicamente
  headers: {
    'x-key-api': xKeyApi, // Clave API dinámica
    'Content-Type': 'application/json',
    'Accept-Language': 'es',
  },
});

// Interceptores opcionales para el manejo de peticiones y respuestas
api.interceptors.request.use(
  (config) => {
    /* console.log('Enviando petición a:', config.url,baseURL,xKeyApi); */
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    //console.log('Respuesta recibida desde:', baseURL);
    return response;
  },
  (error) => {
    console.error('Error en la respuesta:', error);
    return Promise.reject(error);
  }
);

export default api;
