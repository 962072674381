<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { defineComponent, onMounted } from 'vue';

  export default defineComponent({
    name: 'App',
    setup() {
      // Acceder a la variable de entorno
      const shouldLoadHubspot = import.meta.env.VUE_APP_PRODUCTION === 'true';

      onMounted(() => {
        if (shouldLoadHubspot) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'hs-script-loader';
          script.async = true;
          script.defer = true;
          script.src = '//js-na1.hs-scripts.com/47824837.js';
          
          // Agregar el script al head del documento
          document.head.appendChild(script);
        }
      });
    }
  });
</script>
