<template>
    <div class="relative w-full  mt-10">
        <div class="flex w-max flex-nowrap animate-infiniteScrollRight ">
        <img src="/img/presentacion/carrusel1.png" alt="Slide 9" class="object-cover w-full ">
        <img src="/img/presentacion/carrusel1.png" alt="Slide 9" class="object-cover w-full ml-[60px] ">
        </div>
    </div>

    
    <div class="relative w-full overflow-hidden">
        <div class="flex w-max flex-nowrap animate-infiniteScrollLeft mt-[35px] ">
        <img src="/img/presentacion/carrusel2.png" alt="Slide 8" class="object-cover w-full ">
        <img src="/img/presentacion/carrusel2.png" alt="Slide 8" class="object-cover w-full ml-[45px] ">
        </div>
    </div>
</template>


<style scoped>

@keyframes infiniteScrollRight {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }

  @keyframes infiniteScrollLeft {
    from { transform: translateX(-50%); }
    to { transform: translateX(0); }
  }

  .animate-infiniteScrollRight {
    animation: infiniteScrollRight 40s linear infinite;
  }

  .animate-infiniteScrollLeft {
    animation: infiniteScrollLeft 70s linear infinite;
  }
</style>
