<template>
    <div class="flex z-10 gap-6">
        <img src="/img/presentacion/1.TH.logo.png" alt="Slide 10" class="h-[48px]">
        <p class="text-[36px] font-medium">Guest Management System (GMS)</p>
    </div>
    <div class="flex justify-center items-center h-[610px] mt-8">
        <div class="flex flex-col justify-center items-center h-full">
            <p class="text-[30px] font-medium absolute top-[80px] left-[490px] text-[#212121]">ADQUISICIÓN</p>
            <div class="flex flex-col top-[320px] right-[180px] absolute  leading-none">
                <p class="text-[30px] font-medium uppercase text-[#212121]">EXPERIENCIA</p>
                <p class="text-[30px] font-medium uppercase text-[#212121]">DEL HUÉSPED</p>
                <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 28 38" fill="none">
                    <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[24px] font-medium relative text-[#212121]">Satisfacción</p>
                </div>
            </div>
            <div class="flex flex-col bottom-[80px] left-[500px] absolute  leading-none">
              <p class="text-[30px] font-medium uppercase text-[#212121]">fidelización</p>
              <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 28 38" fill="none">
                  <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[24px] font-medium relative text-[#212121]">Demanda</p>
              </div>
            </div>

            <div class="flex flex-col top-[400px] left-[250px] absolute  leading-none">
              <p class="text-[30px] font-medium uppercase text-[#212121]">reputación</p>
              <p class="text-[30px] font-medium uppercase text-[#212121]">online</p>
              <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 28 38" fill="none">
                  <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[24px] font-medium relative text-[#212121]">Posicionamiento <br> en OTAs</p>
              </div>
            </div>


            <img src="/img/presentacion/flechas/flechas4.png" 
                alt="Slide 10" 
                class="w-[90%] max-h object-contain">
        </div>
    </div>
</template>