

<template>
    <div class="flex  w-full h-screen">
      <!-- Columna izquierda con imágenes -->
      <div class="relative w-[650px] flex justify-start items-center">
        <!-- Imagen trasera (absoluta, detrás) -->
        <img 
          src="/img/presentacion/fidelizacion/2.svg"
          alt="Slide 23" 
          class="absolute left-[250px] top-[46%] -translate-y-1/2 z-10 w-[316px] h-[760px]"
        />
        <!-- Imagen delantera -->
        <img 
          src="/img/presentacion/fidelizacion/1.svg"
          alt="Slide 23" 
          class="relative z-20  top-[45%] -translate-y-1/2 w-[316px] h-[760px]"
        />
      </div>
  
      <!-- Columna derecha con contenido -->
      <div class="flex flex-col justify-start mt-[76px] flex-1  space-y-12">
        <!-- Título principal -->
        <div class="flex gap-4 items-center">
          <div class="p-[5px] bg-[#34A98F] rounded-[7.5px]">
            <img src="/img/presentacion/fidelizacion/WA.user.svg" alt="Slide 23" class="w-[70px] h-[70px]" />
          </div>
          <h3 class="text-[60px] font-semibold text-[#0B6357]">Fidelización</h3>
        </div>
        <div class="flex items-start gap-4">
            <img src="/img/presentacion/TH.CHECK.svg" alt="Slide 23" class="w-6 h-6 mt-2">
            <div class="text-[24px]  flex flex-col">
                <h3 class="text-[24px] font-medium  text-[#0B6357]">Capta emails del titular y acompañantes</h3>
                <h3 class="text-[24px] font-normal">Recoge lo que Booking no te da.</h3>
            </div>
        </div>
        <div class="flex items-start gap-4">
            <img src="/img/presentacion/TH.CHECK.svg" alt="Slide 23" class="w-6 h-6 mt-2">
            <div class="text-[24px]  flex flex-col">
                <h3 class="text-[24px] font-medium  text-[#0B6357]">Convierte huéspedes en clientes recurrentes</h3>
                <h3 class="text-[24px] font-normal">Reduce la dependencia de OTAs y genera reservas directas.</h3>
            </div>
        </div>
        <div class="flex items-start gap-4">
            <img src="/img/presentacion/TH.CHECK.svg" alt="Slide 23" class="w-6 h-6 mt-2">
            <div class="text-[24px]  flex flex-col">
                <h3 class="text-[24px] font-medium  text-[#0B6357]">Fomenta el boca a boca digital</h3>
                <h3 class="text-[24px] font-normal">Deja que tus embajadores recomienden tu hotel y atrae nuevos clientes.</h3>
            </div>
        </div>
      </div>
    </div>
  </template>
  