<template>
    <div class="grid grid-cols-2">
        <div class="flex flex-col">
            <div class="flex items-center z-10 gap-6 mb-12">
                <img src="/img/presentacion/1.TH.logo.png" alt="Slide 10" class="h-[40px]">
                <p class="text-[30px] font-medium">GMS</p>
            </div>
            <p class="text-[19px] font-medium absolute top-[60px] left-[220px] between-1440-1919:left-[120px] text-[#212121]">ADQUISICIÓN</p>
            <div class="flex flex-col top-[250px] left-[780px] between-1440-1919:left-[670px] absolute  leading-none">
                <p class="text-[19px] font-medium uppercase">EXPERIENCIA</p>
                <p class="text-[19px] font-medium uppercase">DEL HUÉSPED</p>
                <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 0 28 38" fill="none">
                    <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[15px] font-medium relative">Satisfacción</p>
                </div>
            </div>
            <div class="flex flex-col bottom-[255px] left-[195px] between-1440-1919:left-[105px] between-1440-1919:bottom-[290px] absolute  leading-none">
                <p class="text-[19px] font-medium uppercase">fidelización</p>
                <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 0 28 38" fill="none">
                    <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[15px] font-medium relative">Demanda</p>
                </div>
            </div>
            <div class="flex flex-col top-[305px] left-[1px] between-1440-1919:left-[-40px] absolute  leading-none">
                <p class="text-[19px] font-medium uppercase">reputación</p>
                <p class="text-[19px] font-medium uppercase">online</p>
                <div class="flex mt-1 gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 0 28 38" fill="none">
                    <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-[15px] font-medium relative">Posicionamiento <br> en OTAs</p>
                </div>
            </div>
            <div class="flex flex-col bottom-[295px] left-[420px] between-1440-1919:left-[340px] between-1440-1919:bottom-[310px] absolute items-start  leading-none">
                <div class="flex bg-[#34A98F] p-[10px] rounded-[10px] items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="64" viewBox="0 0 58 74" fill="none">
                        <path d="M29 69V5M29 5L5 27.8571M29 5L53 27.8571" stroke="white" stroke-width="8.49616" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h3 class="text-[31px] font-medium uppercase text-white">ingresos</h3>
                </div>
                <div class="flex p-[10px] items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="64" viewBox="0 0 28 38" fill="none">
                        <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="text-[31px] font-medium uppercase text-[#212121]">ocupación</p>
                </div>
                <div class="flex p-[10px] items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="64" viewBox="0 0 28 38" fill="none">
                        <path d="M14.4286 35V3M14.4286 3L3 14.4286M14.4286 3L25.8571 14.4286" stroke="#2A8973" stroke-width="4.11429" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="text-[31px] font-medium uppercase text-[#212121]">precio</p>
                </div>
            </div>
            <div class="flex justify-center items-center h-[400px]">
                <img src="/img/presentacion/flechas/flechas4.png" alt="Slide 10" class="w-[750px] max-h ">
            </div>
            <!-- Incremento en la facturación -->
            <div class="flex items-center justify-between w-[82%] font-[Rethink Sans] gap-10 pl-[60px] mt-[130px]">
                <label class="text-left text-[28px] font-semibold text-[#212121]">Incremento en<br> la facturación</label>
                <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ increment > 0 || increment == 0 ? '+' : '' }}{{ formattedIncrement ?? 0 }}€</span>
            </div>
        </div>
        <div class="flex justify-center items-center 2xl-1440:mt-[15px] pr-[120px] pl-[290px] 2xl-1440:pr-[140px] 2xl-1440:pl-[140px]" v-if="props.complete">
            <div class="grid grid-cols-1 gap-[64px]">
                <!-- Columna Servicios adicionales -->
                <div class="flex flex-col gap-0 w-[750px] pt-[27px] pr-[24px] pb-[22px] h-[600px] pl-[24px]">
                    <h1 class="font-semibold text-[29px] 2xl-1440:text-[21px] custom-span">Servicios adicionales</h1>
                    <hr class="border border-2 border-black w-[427px]">
                    <!-- Contenedor interno con los servicios adicionales -->
                    <div class="flex w-[550px] flex-col items-start">
                    <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
                            <img src="/img/presentacion/3.svg" alt="Icono Personalización del servicio" class="w-[56px] h-[56px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
                            <p class="text-[24px] font-semibold 2xl-1440:text-[20px]">Personalización del servicio</p>
                        </div>
                        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
                            <img src="/img/presentacion/1.svg" alt="Icono Implementación" class="w-[56px] h-[56px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
                            <p class="text-[24px] font-semibold 2xl-1440:text-[20px]">Implementación en <br> 72hs</p>
                        </div>
                        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
                            <img src="/img/presentacion/2.svg" alt="Icono Capacitación completa" class="w-[56px] h-[56px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
                            <p class="text-[24px] font-semibold 2xl-1440:text-[20px]">Capacitación completa</p>
                        </div>
                        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
                            <img src="/img/presentacion/4.svg" alt="Icono Atención 24/7" class="w-[56px] h-[56px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
                            <p class="text-[24px] font-semibold 2xl-1440:text-[20px]">Atención 24/7</p>
                        </div>
                    </div>
                    <!-- Bloque de precios con transición desde abajo después de 2 segundos -->
                    <transition name="slide-up" mode="out-in">
                        <div v-if="props.price && showPriceDelayed" class="flex flex-col gap-1 mt-[60px]">
                            
                            <!-- Precio Mensual -->
                            <div class="ml-[20px]" v-if="typeDossier == 'A'">
                                <div class="flex items-end gap-4">
                                    <span class="custom-span text-[34px] font-semibold 2xl-1440:text-[20px] text-right">Precio</span>
                                    <span class="text-[24px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
                                        <span class="text-[41px] leading-[110%] custom-span">17</span>,99€
                                    </span>
                                    <span class="text-[24px] font-semibold custom-span">Por habitación al mes</span>
                                </div>
                            </div>

                            <div class="ml-[20px]" v-else>
                                <div class="flex items-end gap-4">
                                    <span class="custom-span text-[34px] font-semibold 2xl-1440:text-[20px] text-right">Precio</span>
                                    <span class="text-[24px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
                                        <span class="text-[41px] leading-[110%] custom-span">599</span>€ por hotel al mes
                                    </span>
                                </div>
                            </div>

                            <!-- Precio de Implementación con espacio alineado -->
                            <div class="ml-[48px] leading-none">
                                <div class="flex items-end gap-4">
                                    <span class="custom-span text-[34px] font-semibold 2xl-1440:text-[20px] text-right opacity-0">Precio</span>
                                    <span class="text-[24px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
                                        <span class="text-[29px] leading-[110%] custom-span">900</span>€
                                    </span>
                                    <span class="text-[24px] font-semibold custom-span leading-none mb-1">de implementación</span>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { ref, onMounted, computed } from 'vue';

    const route = useRoute();

    import { useCalculatorStore } from '@/stores/useCalculatorStore';
    const store = useCalculatorStore();

    const showPriceDelayed = ref(false);

    onMounted(() => {
    setTimeout(() => {
        showPriceDelayed.value = true;
    }, 400);
    });

    const props = defineProps({
        complete: {
            type: Boolean,
            default: false,
        },
        price: {
            type: Boolean,
            default: false,
        },
    });

    const typeDossier = route.params.type;

    function formatCurrency(value) {
        return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); // Eliminando los decimales
    }

    const annualRevenue = computed(() => {
        return Math.ceil((store.form.rooms  * store.form.averagePrice ) * (store.form.openMonths * 31) * (store.form.occupancyRate / 100) || 0);
    });

    const annualRevenueWithHoster = computed(() => {
        return Math.ceil(
                store.form.rooms * store.form.pricePerNightIncrease * (store.form.openMonths * 31) * (store.form.occupancyRateIncrease / 100) || 0
            );
    });


    // Cálculo de "Incremento en la facturación"
    const increment = computed(() => {
        return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value);
        //  return (store.form.rooms * store.form.averagePrice * store.form.occupancyRate * 31) * store.form.openMonths ?? 0;
    });

    const formattedIncrement = computed(() => {
        return formatCurrency(increment.value);
    });

       


</script>
<style scoped>
    .custom-span {
    color: #333 !important;
    font-family: 'Rethink Sans', sans-serif !important;
    }

    /* Estilos de la transición de "slide-up" para hacer que el precio suba desde abajo */
    .slide-up-enter-active, .slide-up-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
    }
    .slide-up-enter-from {
    transform: translateY(100%);
    opacity: 0;
    }
    .slide-up-enter-to {
    transform: translateY(0);
    opacity: 1;
    }
    .slide-up-leave-from {
    transform: translateY(0);
    opacity: 1;
    }
    .slide-up-leave-to {
    transform: translateY(100%);
    opacity: 0;
    }
</style>

